//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchInnochainSettleList, fetchInnochainSettleProperty } from "@/api/finance";
import "@/utils/global.js";
import { downloadFun } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "InnochainSettle",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          property_id: ''
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      totalData: {
        settle_amount: 0,
        order_price: 0,
        order_num: 0,
        refund_price: 0,
        refund_num: 0
      },
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      propertyList: []
    };
  },
  created: function created() {
    var _this = this;

    fetchInnochainSettleProperty().then(function (response) {
      _this.propertyList = response.data;
    });
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this2 = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchInnochainSettleList(data).then(function (response) {
        _this2.totalData = response.totalData;
        _this2.dataList = response.data;
        _this2.totalPage = response.meta.total;
        _this2.total = response.meta.total;
        _this2.tableLoading = false;
      }).catch(function (err) {
        _this2.tableLoading = false;

        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    handleDownload: function handleDownload() {
      this.importLoading = true;
      var data = this.searchForm;
      var url = process.env.VUE_APP_URL + "/finance/innochain/settle/down?token=".concat(getToken(), "&keyword=").concat(data.keyword || "", "\n        &start_time=").concat(data.start_time, "\n        &end_time=").concat(data.end_time, "\n        &filter[property_id]=").concat(data.filter.property_id || '');
      downloadFun(url, "创链日账单.csv");
      this.importLoading = false;
    }
  }
};